import React from 'react';
import logo from './logo.svg';
import Home from "./components/home";

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";




function App() {
  return (
    <div className="App">
     <Home />
    </div>
  );
}

export default App;
